export const menus = [
  // {
  //   id: 1,
  //   name: '产品简介',
  //   path: '/document/description',
  //   children: [{
  //       name: '产品简介',
  //       path: '/document/description',
  //     },
  //     {
  //       name: '产品优势',
  //       path: '/document/advantage',
  //     },
  //     {
  //       name: '应用场景',
  //       path: '/document/scene',
  //     },
  //     {
  //       name: '技术术语',
  //       path: '/document/term',
  //     },
  //     {
  //       name: '发布说明',
  //       path: '/document/releaseNotes',
  //     },
  //   ]
  // },
  // {
  //   id: 2,
  //   name: '操作指南',
  //   path: '/document/loginAndRegister',
  //   children: [{
  //       name: '登录注册',
  //       path: '/document/loginAndRegister',
  //     },
  //     {
  //       name: '身份认证',
  //       path: '/document/idAuthentication',
  //     },
  //     {
  //       name: '存证中心',
  //       path: '/document/saveCenter',
  //     },
  //     {
  //       name: '溯源中心',
  //       path: '/document/resourceCenter',
  //     },
  //     {
  //       name: '应用中心',
  //       path: '/document/appCenter',
  //     },
  //   ]

  // },
  {
    id: 3,
    name: '开发指南',
    path: '/document/quickStart',
    children: [
      // {
      //   name: '快速开始',
      //   path: '/document/quickStart',
      // },
      {
        name: '接入指导',
        path: '/document/accessGuide',
      },
      {
        name: 'API概览',
        path: '/document/apiConcept',
      },
      {
        name: '存证中心',
        path: '/document/depositCertificate',
      },
      {
        name: '溯源中心',
        path: '/document/trace',
      },
    ]

  },
  // {
  //   id: 4,
  //   name: '常见问题',
  //   path: '/document/commonProblem',
  //   children: [{
  //     name: '常见问题',
  //     path: '/document/commonProblem',
  //   }, ]
  // },
]
<template>
  <div class="document-page">
    <div class="w-auto">
      <nav>
        <div class="title">帮助与文档</div>
        <el-breadcrumb class="bread" separator=">">
          <el-breadcrumb-item
            v-for="(item, index) in breadNav"
            :key="item.path + index"
            :to="{ path: item.path }"
            >{{ item.name }}</el-breadcrumb-item
          >
        </el-breadcrumb>
      </nav>

      <div class="layout">
        <div>
          <div class="leftMenu">
            <el-menu
              :default-active="activeMenuItem"
              class="el-menu-vertical-demo"
              :unique-opened="true"
              :collapse="isCollapse"
              @open="handleOpen"
              @close="handleClose"
            >
              <el-submenu
                class="firstMenu"
                v-for="(item, index) in menus"
                :key="item.id"
                :index="item.path"
                @click="changeFirstMenu(item)"
              >
                <template #title>
                  <i class="collapseText" v-show="isCollapse">
                    {{ item.name }}</i
                  >
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item
                  :class="{ childMenu: $route.path.indexOf(value.path) != -1 }"
                  v-for="(value, childIndex) in item.children"
                  :key="value.name"
                  :index="value.path"
                  @click="changeChildMenu(value)"
                  >{{ value.name }}</el-menu-item
                >
              </el-submenu>
            </el-menu>
          </div>
        </div>
        <div class="centerContent">
          <router-view></router-view>
        </div>
        <div>
          <!-- 锚点线 -->
          <ul class="right">
            <li
              class="anchor"
              :class="{ active: item.id == activeId }"
              @click="jumpTo(item.id)"
              v-for="item in anchorData"
              :key="item.id"
            >
              <a :href="'#' + item.id">{{ item.title }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { menus } from "./menu_data";
import { watchScreenWidth } from "@/utils/watchScreenWidth.js";
export default {
  data() {
    return {
      isCollapse: false, //侧边栏展开状态
      activeId: "doc0",
      menus, //侧边栏内容
      anchorData: [{ id: 1, title: "锚点1" }], //锚点数据
      activeMenuItem: "/document/description", //侧边栏激活项
      breadNav: [{ id: 1, name: "帮助与文档", path: "/helpCenter" }], //当前面包屑
      braedTemplate: [{ id: 1, name: "帮助与文档", path: "/helpCenter" }], //模板
      element: {},
    };
  },
  methods: {
    handleClose() {},
    handleOpen() {},

    changeFirstMenu(item) {
      this.breadNav.push(item);
      this.$router.push(item.path);
    },
    changeChildMenu(item) {
      // 面包屑
      menus.forEach((v) => {
        v.children.forEach((value) => {
          if (value.path === item.path) {
            this.breadNav = [...this.braedTemplate];
            this.breadNav.push(v);
            this.breadNav.push(item);
            return;
          }
        });
      });

      this.$router.push(item.path);
      // 返回顶部
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      // 延迟获取锚点链接
      setTimeout(() => {
        this.getAnchor();
      }, 100);
    },
    // 初始化刷新页面根据路由改变激活项,添加面包屑
    initActiveItem() {
      const path = this.$route.path;
      menus.forEach((v) => {
        v.children.forEach((item) => {
          if (item.path === path) {
            this.breadNav = [...this.braedTemplate];
            this.breadNav.push(v);
            this.breadNav.push(item);
            return (this.activeMenuItem = item.path);
          }
        });
      });
    },
    // 锚点跳转
    jumpTo(id) {
      this.activeId = id;
      // 锚点滚动
      // document.getElementById("doc" + id).scrollIntoView();
    },
    // 获取h3标题作为锚点导航链接
    getAnchor() {
      const h3Ele = document.querySelectorAll(".document-page h3");
      // console.log(h3Ele, "获取标题H3");
      // console.log(h3Ele[0].innerText);
      const anchorArr = [];
      if (h3Ele.length != 0) {
        h3Ele.forEach((v, i) => {
          v.setAttribute("id", "doc" + i);
          if (v.innerText !== "") {
            anchorArr.push({ id: "doc" + i, title: v.innerText });
          }
        });
        this.anchorData = anchorArr;
        console.log(this.anchorData, "数据");
      } else {
        this.anchorData = [];
      }
    },
    initScreenWidth() {
      // 判断初始屏幕大小
      if (
        (document.body.clientWidth || document.documentElement.clientWidth) <
        768
      ) {
        this.isCollapse = true;
      } else {
        this.isCollapse = false;
      }
      // 监听屏幕宽度变化
      watchScreenWidth(
        768,
        // 小于768px 时
        () => {
          this.isCollapse = true;
        },
        // 大于768px时
        () => {
          this.isCollapse = false;
        }
      );
    },
    watchScroll() {
      let resizeWaiter = false;
      window.addEventListener("scroll", (res) => {
        if (!resizeWaiter) {
          resizeWaiter = true;
          setTimeout(() => {
            // console.log(res, "滚动回调");
            // 屏幕滚去距离
            let docOffsetTop = document
              .querySelector(".centerContent")
              .getBoundingClientRect().top;
            let scrollTop = 0 - docOffsetTop;

            // let scrollTop = document.body.scrollTop || this.element.scrollTop;
            // console.log(scrollTop, "屏幕滚去距离");
            // 遍历锚点链接数据
            this.anchorData.forEach((v, i) => {
              // console.log(document.getElementById(v.id));
              // 获取各锚点距离顶部距离
              const eleHeight = document.getElementById(v.id).offsetTop;
              // console.log(eleHeight, "锚点距离顶部距离");

              if (i > 0) {
                // 当滚去距离位于两锚点距离顶部距离时，跳转至前一锚点位置
                // console.log(scrollTop, eleHeight);
                // scrollTop + 5 忽略因像素取整带来的影响
                if (
                  eleHeight <= scrollTop + 5 &&
                  eleHeight >
                    document.getElementById(this.anchorData[i - 1].id).offsetTop
                ) {
                  this.activeId = v.id;
                }
              } else {
                //屏幕滚去距离小于第一个锚点距离顶部距离时跳转至第一个锚点位置（忽略第一至第二锚点之间距离）
                if (eleHeight >= scrollTop) {
                  this.activeId = "doc0";
                }
              }
            });
            resizeWaiter = false;
          }, 300);
        }
      });
    },
  },
  async mounted() {
    // 侧边栏激活样式初始化
    this.initActiveItem();
    // 获取锚点链接数据
    await this.getAnchor();
    // 监听屏幕滚动
    await this.watchScroll();

    const hash = this.$route.hash;
    console.log(hash);
    // 获取锚点跳转

    // console.log(document.querySelectorAll(".document-page #doc4"));
    // console.log(hash.substring(1, hash.length - 1));
    if (hash) {
      console.log("触发锚点跳转");
      document.querySelector(hash).scrollIntoView();
    }
  },
  async created() {
    // dom挂载前判断屏幕大小初始化侧边栏展开状态
    this.initScreenWidth();
    // this.element = document.documentElement;
  },
};
</script>

<style lang="less" scoped>
/deep/.markdown-body {
  background-color: transparent;
}
.document-page {
  padding-top: 80px;
  background-color: #f9f9f9;
  nav {
    height: 80px;
    display: flex;
    align-items: center;
    .title {
      width: 210px;
      font-size: 24px;
      color: #333;
    }
    .bread {
      padding-left: 70px;
    }
  }

  .layout {
    display: flex;
    position: relative;
    .leftMenu {
      width: 210px;
      position: sticky;
      top: 0;
      left: 0;
    }

    /deep/ .right {
      width: 180px;
      position: sticky;
      top: 0;
      left: 0;
      padding-left: 15px;
      border-left: 2px solid #e8e8e8;
      .el-timeline-item__tail {
        left: 3px;
      }
      .el-timeline-item__node--normal {
        width: 6px;
        height: 6px;
        left: 1px;
      }
      .anchor {
        cursor: pointer;
        line-height: 30px;
        position: relative;
      }

      .anchor.active {
        a {
          color: #3d6df1;
        }
        &::before {
          position: absolute;
          left: -19px;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          // border: 2px solid #66b1ff;
          box-shadow: 0 0 4px 1px #3d6df1;
          background-color: #3d6df1;
        }
      }
    }
    .centerContent {
      margin: 0 30px 0 70px;
      flex: 1;
      padding-bottom: 30px;

      /deep/ .docHeader {
        position: relative;
        h2 {
          height: 60px;
          line-height: 60px;
          border-bottom: 1px solid #d8dee4;
        }
        .updateTime {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          font-size: 14px;
          color: #777a8c;
        }
      }
    }
  }
  /deep/ .el-menu {
    border-right: 0;
    background-color: transparent;
    .el-menu-item,
    .el-submenu__title {
      height: 40px;
      line-height: 40px;
      .collapseText {
        color: #333;
      }
    }
    // .el-submenu .el-menu-item {
    //   height: 32px;
    //   line-height: 32px;
    // }
    .el-submenu__title:hover {
      background-color: #3d6df1;
      color: #fff;
      .collapseText {
        color: #fff;
      }
    }
    .el-menu-item:hover {
      background-color: #3d6df1;
      color: #fff;
    }

    .childMenu {
      background-color: #3d6df1;
      color: #fff;
    }
    // .firstMenu {
    //   background-color: #3d6df1;
    //   color: #fff;
    // }
  }
  .el-menu-vertical-demo {
    .el-menu-item {
      padding-left: 70px !important;
    }
  }
}
// 1200px以上屏幕
@media screen and(min-width:1200px) {
  /deep/.document-content {
    width: 710px;
    img {
      width: 710px;
    }
  }
}

// 1200px屏幕以下
@media screen and(max-width:1200px) {
  .document-page {
    nav {
      height: 60px;
    }
    .layout {
      .right {
        display: none;
      }
    }
  }

  .Description {
    width: calc(100vw - 328px);
    // .markdown-body {
    //   width: 800px;
    // }
  }
  /deep/ .Advantage {
    width: calc(100vw - 328px);
    img {
      width: calc(100vw - 328px);
    }
  }
}

@media screen and(max-width:768px) {
  .document-page {
    padding-top: 0;
    .title {
      display: none;
    }
    /deep/ .layout {
      .leftMenu {
        width: 40px;
        .el-submenu__title {
          padding: 0 5px !important;
        }
      }
      .centerContent {
        margin: 0 30px 0 40px;
        width: calc(100vw - 100px);
      }
    }

    .Description {
      width: calc(100vw - 100px);
      // .markdown-body {
      //   width: 800px;
      // }
    }

    /deep/ .Advantage {
      width: calc(100vw - 100px);
      img {
        width: calc(100vw - 100px);
      }
    }
  }
}
</style>
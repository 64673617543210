/* 
 *
 ** limitSize：宽度限制条件 数值 （未做数据类项判断）默认值520px 
 ** lessWidth： 当屏幕小于limitSize时触发的回调
 ** greaterWidth： 当屏幕大于limitSize时触发的回调
 *  
 */
const lessWidthDefault = () => {
  console.log('未传小于条件参数时的回调参数');
}
const greaterWidthD = () => {
  console.log('未传大于条件参数时的回调参数');
}

export const watchScreenWidth = (limitSize = 520, lessWidth = lessWidthDefault, greaterWidth = greaterWidthD) => {
  // 节流状态
  let resizeWaiter = false;
  // 监听屏幕宽度变化
  window.onresize = () => {
    // 判断节流状态是否开启，未开启则执行业务代码
    if (!resizeWaiter) {
      // 节流状态开启
      resizeWaiter = true;
      // 延迟300ms 触发事件代码
      setTimeout(() => {
        // 兼容不同浏览器
        const screenWidth = document.body.clientWidth || document.documentElement.clientWidth;
        if (screenWidth < limitSize) {
          lessWidth()
          // console.log('触发小于限制宽度');
        } else {
          greaterWidth()
          // console.log('触发大于限制宽度');
        }
        // 业务代码执行后，节流状态关闭
        resizeWaiter = false;
      }, 300);
    }
  };
}